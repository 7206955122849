/*
* Global Loader Responsive
*/

@media (min-width: 768px) and (max-width: 1023px) {
	.loader-res {
		left: 44% !important;
	}
}

@media (min-width: 520px) and (max-width: 768px) {
	.loader-res {
		left: 44% !important;
	}
}

@media only screen and (max-width: 520px) {
	.loader-res {
		left: 37% !important;
	}
}

.loader-container {
	z-index: 99999 !important;
}

.loader-container {
	z-index: 99999 !important;
}

/* 

@media (min-width: 768px) and (max-width: 1023px) {
  .loader-container{
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .loader-container{
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
} */
